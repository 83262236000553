import "normalize.css";
import "react-datepicker/dist/react-datepicker.css";

import { Global, css } from "@emotion/core";

import { StrictMode } from "react";
import { AppProps } from "next/app";
import { DataProvider } from "../src/DataContext";
import { AuthProvider, useAuth } from "../src/AuthContext";
import Head from "next/head";
import Link from "next/link";
import styled from "@emotion/styled";
import { Spacer } from "../src/Spacer";

const NavLink = styled("a")({
  color: "white !important",
  fontSize: "1.1em",
  marginRight: "8px",
  textDecoration: "none",
  cursor: "pointer",
  borderBottom: "2px solid white",
  textTransform: "uppercase",
});

const Header = () => {
  const { user, logout } = useAuth();

  const handleLogout = () => {
    if (window.confirm("¿Quieres desconectarte?")) {
      logout();
    }
  };

  return (
    <header>
      <div
        style={{
          background: "black",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 16px",
          height: "60px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link href="/">
            <img
              alt="Editorial"
              src="/logo.png"
              style={{ width: "auto", height: "60px", display: "block" }}
            />
          </Link>

          <nav style={{ marginLeft: "16px" }}>
            <Link href="/" passHref>
              <NavLink>Home</NavLink>
            </Link>
            <Link href="/descubridor">
              <NavLink>Descubridor</NavLink>
            </Link>
            {(user.roles.includes("administrator") ||
              user.roles.includes("editor")) && (
              <Link href="/analytics">
                <NavLink>Analytics</NavLink>
              </Link>
            )}
            <NavLink
              href="https://themedizine.workplace.com/groups/870939583404051/"
              target="_blank"
            >
              Workplace
            </NavLink>
          </nav>
        </div>

        <div>
          <button
            onClick={handleLogout}
            style={{
              border: 0,
              background: 0,
              height: "40px",
              width: "40px",
              padding: 0,
            }}
          >
            <img
              src={user.image}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "100%",
                display: "block",
              }}
            />
          </button>
        </div>
      </div>

      <div
        style={{
          background: "#ccc",
          color: "black",
          display: "flex",
          alignItems: "baseline",
          // justifyContent: "space-between",
          padding: "4px 16px",
          // height: "60px",
        }}
      >
        <span style={{ fontWeight: "bold" }}>LINKS WORDPRESS:</span>
        <Spacer w={16} />
        <a
          href="https://cms.themedizine.com/wp-admin/post-new.php"
          target="_blank"
        >
          Nueva noticia
        </a>
        <Spacer w={16} />
        <a
          href="https://cms.themedizine.com/wp-admin/edit.php?post_status=draft&post_type=post"
          target="_blank"
        >
          Borradores
        </a>
        <Spacer w={16} />
        <a
          href="https://cms.themedizine.com/wp-admin/edit.php?post_status=pending&post_type=post"
          target="_blank"
        >
          Pendientes
        </a>
      </div>
    </header>
  );
};

export const LoggedApp = ({ Component, pageProps }: AppProps) => {
  return (
    <DataProvider>
      <Global
        styles={css`
          html,
          body {
            -webkit-text-size-adjust: 100%;
            text-rendering: optimizeLegibility;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
          }

          * {
            box-sizing: border-box;
            max-width: 100%;
            font-family: "Roboto", sans-serif;
          }
        `}
      />
      <Header />
      {/* <RenderOnlyClient> */}
      <Component {...pageProps} />
      {/* </RenderOnlyClient> */}
    </DataProvider>
  );
};

const App = (props) => (
  <>
    <StrictMode>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap"
          rel="stylesheet"
        />
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <script src="https://apis.google.com/js/client:platform.js"></script>
      </Head>
      <AuthProvider
        renderChildren={() => <LoggedApp {...props} />}
      ></AuthProvider>
    </StrictMode>
    ,
  </>
);

export default App;
